var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-page",staticStyle:{"height":"auto"}},[_c('div',{staticClass:"auth-wrapper auth-v1"},[_c('div',{staticClass:"auth-inner"},[_c('v-card',{staticClass:"auth-card"},[_c('v-card-title',{staticClass:"d-flex align-center justify-center py-7"},[_c('router-link',{staticClass:"d-flex align-center",attrs:{"to":"/"}},[_c('h2',{staticClass:"text-2xl font-weight-semibold"},[_vm._v(" STRONG EXPERT ")])])],1),_c('v-card-text',[(_vm.hasInitialCredit)?_c('v-alert',{attrs:{"dense":"","text":"","outlined":"","type":"success"}},[_vm._v(" Garanta seu bônus especial ao se cadastrar agora! "),_c('br'),_vm._v(" Experimente nossa plataforma e veja como podemos transformar seus resultados! "),_c('br')]):_vm._e(),_c('validation-observer',{ref:"form",attrs:{"autocomplete":"off","tag":"form"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.register()}}},[_c('validation-provider',{attrs:{"name":"Nome","rules":"required|max:100","vid":"name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"outlined":"","dense":"","label":"Nome"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})]}}])}),_c('validation-provider',{attrs:{"name":"E-mail","rules":"required|email","vid":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"outlined":"","dense":"","label":"E-mail"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}])}),_c('validation-provider',{attrs:{"name":"Senha","rules":"required","vid":"password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"append-icon":_vm.isPasswordVisible ? _vm.icons.mdiEyeOffOutline : _vm.icons.mdiEyeOutline,"error-messages":errors,"type":_vm.isPasswordVisible ? 'text' : 'password',"label":"Senha","outlined":"","dense":""},on:{"click:append":function($event){_vm.isPasswordVisible = !_vm.isPasswordVisible}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})]}}])}),_c('validation-provider',{attrs:{"name":"Confirmar Senha","rules":"required|confirmed:password","vid":"passwordConfirmation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"append-icon":_vm.isPasswordConfirmationVisible ? _vm.icons.mdiEyeOffOutline : _vm.icons.mdiEyeOutline,"error-messages":errors,"type":_vm.isPasswordConfirmationVisible ? 'text' : 'password',"label":"Confirmar Senha","outlined":"","dense":""},on:{"click:append":function($event){_vm.isPasswordConfirmationVisible = !_vm.isPasswordConfirmationVisible}},model:{value:(_vm.passwordConfirmation),callback:function ($$v) {_vm.passwordConfirmation=$$v},expression:"passwordConfirmation"}})]}}])}),_c('validation-provider',{attrs:{"name":"Política de privacidade","rules":"checkbox_required","vid":"privacyPolicy"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[_c('v-checkbox',{staticStyle:{"margin-bottom":"6px"},attrs:{"error-messages":errors,"label":"","hide-details":"","dense":""},model:{value:(_vm.privacyPolicy),callback:function ($$v) {_vm.privacyPolicy=$$v},expression:"privacyPolicy"}}),_c('span',[_vm._v(" Li e concordo com a "),_c('a',{staticStyle:{"color":"lightblue"},attrs:{"href":"https://strong.expert/politica-de-privacidade/"}},[_vm._v("Política de Privacidade")])])],1)]}}])}),_c('validation-provider',{attrs:{"name":"Termos de usuário","rules":"checkbox_required","vid":"acceptTerms"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticStyle:{"display":"flex","align-items":"center","margin-bottom":"10px"}},[_c('v-checkbox',{staticStyle:{"margin-bottom":"6px"},attrs:{"error-messages":errors,"label":"","hide-details":"","dense":""},model:{value:(_vm.acceptTerms),callback:function ($$v) {_vm.acceptTerms=$$v},expression:"acceptTerms"}}),_c('span',[_vm._v("Li e concordo com os "),_c('a',{staticStyle:{"color":"lightblue"},attrs:{"href":"https://strong.expert/termos-de-uso/"}},[_vm._v(" Termos de Uso")])])],1)]}}])}),_c('v-btn',{attrs:{"block":"","color":"primary"},on:{"click":function($event){return _vm.register()}}},[_vm._v(" Cadastrar ")]),_c('div',{staticClass:"d-flex align-center justify-center flex-wrap mt-6"},[_c('div',{attrs:{"id":"g_id_onload","data-client_id":_vm.googleClientId,"data-context":"signin","data-ux_mode":"popup","data-callback":"handleCredentialResponse","data-auto_prompt":"false"}}),_c('div',{staticClass:"g_id_signin",attrs:{"data-type":"standard","data-shape":"pill","data-theme":"outline","data-text":"continue_with","data-size":"large","data-logo_alignment":"left"}})])],1)],1),_c('v-card-text',{staticClass:"d-flex align-center justify-center flex-wrap mt-2"},[_c('span',{staticClass:"me-2"},[_vm._v(" Já tem uma conta? ")]),_c('router-link',{attrs:{"to":{ name: 'signin' }}},[_vm._v(" Faça login ")])],1)],1)],1),_vm._l((6),function(index){return _c('div',{key:index,staticClass:"cube"})})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }